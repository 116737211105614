var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "pb-2"
  }, [_c('b-container', [_c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    staticClass: "parents-info-title",
    attrs: {
      "cols": "9",
      "md": "3"
    }
  }, [_vm._v(_vm._s(_vm.$t('parentsInfo')))])], 1)], 1)], 1), _c('hr', {
    staticClass: "title-hr"
  }), _c('b-container', [_c('b-row', _vm._l(_vm.parentsPofileInfo, function (parentInfo) {
    return _c('b-col', {
      key: parentInfo.created_at,
      staticClass: "mt-3 parent-info-card px-1 px-md-2",
      attrs: {
        "md": "6",
        "cols": "12"
      }
    }, [parentInfo.mode ? _c('edit-info-card', {
      attrs: {
        "mode": parentInfo.mode,
        "parentInfo": parentInfo,
        "relationOptions": _vm.relationOptions,
        "genderOptions": _vm.genderOptions
      },
      on: {
        "updateCardView": _vm.updateCardView,
        "editParentInfo": _vm.editParentInfo,
        "cancelAddParentInfo": _vm.cancelAddParentInfo
      }
    }) : _c('view-info-card', {
      attrs: {
        "parentInfo": parentInfo
      },
      on: {
        "updateCardView": _vm.updateCardView
      }
    })], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }