var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "removeParentInfo",
      "effect": "fade",
      "size": "sm",
      "title": _vm.$t('removeParentInfo'),
      "ok-title": _vm.$t('confirm'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": function ($event) {
        return _vm.removeParentInfo(_vm.currentRemoveInfoData.id);
      }
    }
  }, [_c('b-container', [_c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t(_vm.currentRemoveInfoData.relation)))])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('chineseName')) + "：")]), _c('span', {
    staticClass: "float-right"
  }, [_vm._v(_vm._s(_vm.currentRemoveInfoData.chineseName))])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('englishName')) + "：")]), _c('span', {
    staticClass: "float-right"
  }, [_vm._v(_vm._s(_vm.currentRemoveInfoData.englishName))])])], 1)], 1), _c('hr')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }