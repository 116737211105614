<template lang="pug">
div
  h4.pb-2
    b-container
      b-row(align-h="between")
        b-col.parents-info-title(cols="9" md="3") {{ $t('parentsInfo') }}
        //- 學員後台新增按鈕先隱藏
        //- b-col(cols="3" md="3")
        //-   b-button.float-right(@click="addParentInfo" variant="primary")
        //-     font-awesome-icon(icon='plus')
  hr.title-hr
  b-container
    b-row
        b-col.mt-3.parent-info-card.px-1.px-md-2(v-for="parentInfo in parentsPofileInfo" :key="parentInfo.created_at" md="6" cols="12")
          edit-info-card(
            v-if="parentInfo.mode"
            :mode="parentInfo.mode"
            :parentInfo="parentInfo"
            :relationOptions="relationOptions"
            :genderOptions="genderOptions"
            @updateCardView="updateCardView"
            @editParentInfo="editParentInfo"
            @cancelAddParentInfo="cancelAddParentInfo"
          )
          view-info-card(
            v-else
            :parentInfo="parentInfo"
            @updateCardView="updateCardView"
          )

</template>

<script>
import { alertSuccessMessage, alertFailedMessage } from '@/utils/sweetAlert.js';
import httpCodeConstants from '@/constants/httpCode';
import lioshutanApi from '@/api';
import editInfoCard from '@/components/parents/profileInfo/infoCard/editInfoCard/index.vue';
import viewInfoCard from '@/components/parents/profileInfo/infoCard/viewInfoCard/index.vue';
import { getGenderOptions, getRelationOptions } from '@/composable/options';
import i18n from '@/components/parents/profileInfo/lang';

export default {
  name: 'ParentsProfile',

  components: {
    editInfoCard,
    viewInfoCard,
  },

  i18n,

  props: {
    parentsPofileInfo: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      addMode: 'add', // 當資料為新增
      editMode: 'edit', // 當資料為編輯
      relationOptions: [
        { value: null, text: this.$t('pleaseSelect') + this.$t('relation') },
      ],
      genderOptions: [
        { value: null, text: this.$t('pleaseSelect') + this.$t('gender') },
      ],
    };
  },

  created(){
    this.getOptionsValue();
  },

  methods: {
    addParentInfo(){
      // TODO NOTICE 後端回傳的資料格式先以 _ 方式命名 By Asa
      const newParentInfo = {
        mode: this.addMode,
        relation: null,
        chinese_name: '',
        english_name: '',
        gender: null,
        cell_phone_number: '',
        email: '',
      };
      // 先以一次新增一筆，若需要新增多筆可在優化階段製作
      if (!this.addParentPofileInfoUpperLimit(this.parentsPofileInfo)) {
        // 空資料新增於第一筆
        this.parentsPofileInfo.unshift(newParentInfo);
      }
      // 若在點擊一次將於Card外框顯示紅色警告，表示只能一次新增一筆，於優化階段製作
      // else if
    },

    addParentPofileInfoUpperLimit(parentsPofileInfo){
      // 當資料為空時可直接新增
      if (parentsPofileInfo.length !== 0){
        return (parentsPofileInfo[0].mode === this.addMode);
      } else {
        return false;
      }
    },

    updateCardView(){
      this.$forceUpdate();
    },

    cancelAddParentInfo(){
      this.parentsPofileInfo.shift();
    },

    getOptionsValue(){
      this.relationOptions = getRelationOptions();
      this.genderOptions = getGenderOptions();
    },

    async editParentInfo(parentInfo){
      let response = '';
      const params = {
        re: parentInfo.relation,
        cn: parentInfo.chinese_name,
        en: parentInfo.english_name,
        em: parentInfo.email,
        cpn: parentInfo.cell_phone_number,
        ge: parentInfo.gender,
      };
      try {
        if (parentInfo.mode === this.editMode){
          params.vpi = parentInfo.id;
          response = await lioshutanApi.parent.editInfo(params);
          if (response.status === httpCodeConstants.SUCCESS){
            alertSuccessMessage(this.$t('editSuccess'));
            this.$emit('reloadParentsInfo', true);
          }
        } else if (parentInfo.mode === this.addMode){
          response = await lioshutanApi.parent.addInfo(params);
          if (response.status === httpCodeConstants.SUCCESS){
            alertSuccessMessage(this.$t('addSuccess'));
            this.$emit('reloadParentsInfo', true);
          }
        }
      } catch (error) {
        alertFailedMessage(this.$t('handleFailed'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.parents-info-title{
  color: var( --main-dark-color );
}
.btn {
  background-color: var( --main-dark-color );
  border-color: var( --main-dark-color );
  border-radius: 10px;
}
.title-hr {
  border-top: 1px solid var( --main-dark-color );
}
.parent-info-card{
  color: var( --text-light-color );
}
</style>
