var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-group', {
    attrs: {
      "deck": ""
    }
  }, [_c('b-card', {
    attrs: {
      "title": _vm.$t(`relation_of_.${_vm.parentInfo.relation}`),
      "footer-tag": "footer"
    }
  }, [_c('hr', {
    staticClass: "card-title-hr"
  }), _c('b-container', {
    staticClass: "bv-example-row bv-example-row-flex-cols"
  }, [_c('b-row', {
    staticClass: "mt-2 justify-content-md-center"
  }, [_c('b-col', {
    attrs: {
      "lg": "10",
      "sm": "12"
    }
  }, [_vm._v(_vm._s(_vm.$t('chineseName')) + "："), _c('span', {
    staticClass: "float-right"
  }, [_vm._v(_vm._s(_vm.parentInfo.chinese_name))])])], 1), _c('b-row', {
    staticClass: "mt-2 justify-content-md-center"
  }, [_c('b-col', {
    attrs: {
      "lg": "10",
      "sm": "12"
    }
  }, [_vm._v(_vm._s(_vm.$t('englishName')) + "："), _c('span', {
    staticClass: "float-right"
  }, [_vm._v(_vm._s(_vm.parentInfo.english_name))])])], 1), _c('b-row', {
    staticClass: "mt-2 justify-content-md-center"
  }, [_c('b-col', {
    attrs: {
      "lg": "10",
      "sm": "12"
    }
  }, [_vm._v(_vm._s(_vm.$t('gender')) + "："), _c('span', {
    staticClass: "gender-text float-right"
  }, [_vm._v(_vm._s(_vm.$t(_vm.parentInfo.gender))), _c('font-awesome-icon', {
    staticClass: "ml-1",
    class: [_vm.parentInfo.gender === 'male' ? 'text-primary' : 'text-danger'],
    attrs: {
      "icon": _vm.getGenferIcon(_vm.parentInfo.gender)
    }
  })], 1)])], 1), _c('b-row', {
    staticClass: "mt-2 justify-content-md-center"
  }, [_c('b-col', {
    attrs: {
      "lg": "10",
      "sm": "12"
    }
  }, [_vm._v(_vm._s(_vm.$t('phoneNumber')) + "："), _c('span', {
    staticClass: "float-right"
  }, [_vm._v(_vm._s(_vm.parentInfo.cell_phone_number))])])], 1), _c('b-row', {
    staticClass: "mt-2 justify-content-md-center"
  }, [_c('b-col', {
    attrs: {
      "lg": "10",
      "sm": "12"
    }
  }, [_vm._v(_vm._s(_vm.$t('email')) + "："), _c('span', {
    staticClass: "float-right"
  }, [_vm._v(_vm._s(_vm.parentInfo.email))])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }