<template lang="pug">
div
  .wuwow-card
    .wuwow-card-head
      h4.h4-notification-title {{ $t('parentZone') }}
    .wuwow-card-body
      profile-info(:parentsPofileInfo='parentsPofileInfo' @reloadParentsInfo="reloadParentsInfo")
  remove-parentInfo(@reloadParentsInfo="reloadParentsInfo")
</template>

<script>
import profileInfo from '@/components/parents/profileInfo';
import removeParentInfo from '@/components/modal/parents/removeParentInfo';
import lioshutanApi from '@/api';

export default {
  name: 'Parents',

  components: {
    profileInfo,
    removeParentInfo,
  },

  data() {
    return {
      parentsPofileInfo: [],
    };
  },

  created(){
    this.getParentsPofileInfo();
  },

  methods: {
    async getParentsPofileInfo(){
      const response = await lioshutanApi.parent.getInfo();
      this.parentsPofileInfo = response.data.data.vip_parent;
    },
    reloadParentsInfo(status){
      if (status){
        this.getParentsPofileInfo();
      }
    },
  },
};
</script>

<style>

</style>
