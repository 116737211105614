<template lang="pug">
b-modal#removeParentInfo(effect='fade' size="sm" :title="$t('removeParentInfo')" @ok="removeParentInfo(currentRemoveInfoData.id)" :ok-title="$t('confirm')" :cancel-title="$t('cancel')")
  b-container
      b-row(align-h="between")
        b-col(cols="12")
          h3 {{ $t(currentRemoveInfoData.relation) }}
      b-row
        b-col(cols="12")
          span {{ $t('chineseName') }}：
          span.float-right {{ currentRemoveInfoData.chineseName }}
      b-row
        b-col(cols="12")
          span {{ $t('englishName') }}：
          span.float-right {{ currentRemoveInfoData.englishName }}
  hr
</template>

<script>
import { mapState } from 'vuex';
import { alertSuccessMessage, alertFailedMessage } from '@/utils/sweetAlert.js';
import httpCodeConstants from '@/constants/httpCode';
import i18n from '@/components/parents/profileInfo/lang';
import lioshutanApi from '@/api';

export default {
  name: 'RemoveParentInfo',

  i18n,

  computed: {
    ...mapState({
      currentRemoveInfoData: state => state.parents.currentRemoveInfoData,
    }),
  },

  methods: {
    async removeParentInfo(id){
      try {
        const removeParams = {
          vpi: id,
        };
        const response = await lioshutanApi.parent.removeInfo(removeParams);
        if (response.status === httpCodeConstants.SUCCESS){
          alertSuccessMessage(this.$t('removeSuccess'));
          this.$emit('reloadParentsInfo', true);
        }
      } catch (error) {
        alertFailedMessage(this.$t('removeFailed'));
      }
    },
  },
};
</script>
