<template lang="pug">
b-card-group(deck)
  b-card(:title="parentInfo.mode === addMode ? $t('add')+$t('parentsInfo') : $t('edit')+$t('parentsInfo')")
    hr
    b-row.mt-2.justify-content-md-center
      b-col(lg="3" sm="12")
        | {{ $t('relation') }}：
      b-col(lg="7" sm="12")
        b-form-select.float-right.parent-info-card(v-model="parentInfo.relation" :options="relationOptions")
    b-row.mt-2.justify-content-md-center
      b-col(lg="3" sm="12")
        | {{ $t('chineseName') }}：
      b-col(lg="7" sm="12")
        b-form-input.float-right(v-model="parentInfo.chinese_name")
    b-row.mt-2.justify-content-md-center
      b-col(lg="3" sm="12")
        | {{ $t('englishName') }}：
      b-col(lg="7" sm="12")
        b-form-input.float-right(v-model="parentInfo.english_name")
    b-row.mt-2.justify-content-md-center
      b-col(lg="3" sm="12")
        | {{ $t('gender') }}：
      b-col(lg="7" sm="12")
        b-form-select.float-right.parent-info-card(v-model="parentInfo.gender" :options="genderOptions")
    b-row.mt-2.justify-content-md-center
      b-col(lg="3" sm="12")
        | {{ $t('phoneNumber') }}：
      b-col(lg="7" sm="12")
        b-form-input.float-right(v-model="parentInfo.cell_phone_number")
    b-row.mt-2.justify-content-md-center
      b-col(lg="3" sm="12")
        | {{ $t('email') }}：
      b-col(lg="7" sm="12")
        b-form-input.float-right(v-model="parentInfo.email")
    hr
    b-button-group.ml-2.float-right
        b-button(@click="editParentInfo(mode)" size="sm" variant="outline-success ")
          font-awesome-icon(icon='check')
        b-button(@click="cancelEditParentInfo(mode)" size="sm" variant="outline-secondary")
          font-awesome-icon(icon='reply')
</template>

<script>
import i18n from '@/components/parents/profileInfo/lang';
export default {
  name: 'EditInfoCard',

  i18n,

  props: {
    mode: {
      type: String,
      default: function() {
        return this.addMode;
      },
    },

    parentInfo: {
      type: Object,
      default: function() {
        return {};
      },
    },

    relationOptions: {
      type: Array,
      default: function() {
        return [{ value: null, text: this.$t('pleaseSelect') + this.$t('relation') }];
      },
    },

    genderOptions: {
      type: Array,
      default: function() {
        return [{ value: null, text: this.$t('pleaseSelect') + this.$t('gender') }];
      },
    },
  },

  data() {
    return {
      addMode: 'add', // 當資料為新增
      editMode: 'edit', // 當資料為編輯
    };
  },

  methods: {
    editParentInfo(mode){
      // 傳送目前的mode給父層判斷整理
      this.parentInfo.mode = mode;
      this.$emit('editParentInfo', this.parentInfo);
    },
    cancelEditParentInfo(mode){
      if (mode === this.editMode){
        delete this.parentInfo.mode;
        this.$emit('updateCardView', true);
      } else if (mode === this.addMode){
        this.$emit('cancelAddParentInfo', true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  color: var( --main-dark-color );
}
hr {
  border-top: 1px solid var( --main-dark-color );
}
.parent-info-card{
  color: var( --text-light-color );
  border-color: var( --main-dark-color );
  .card {
    border: 1px solid var( --text-light-color );
    border-radius: 15px;
  }
}
input {
  border-color: var( --main-dark-color );
}
</style>
