<template lang="pug">
b-card-group(deck)
  b-card(:title="$t(`relation_of_.${parentInfo.relation}`)" footer-tag="footer")
    hr.card-title-hr
    b-container.bv-example-row.bv-example-row-flex-cols
      b-row.mt-2.justify-content-md-center
        b-col(lg="10" sm="12") {{ $t('chineseName') }}：
          span.float-right {{ parentInfo.chinese_name }}
      b-row.mt-2.justify-content-md-center
        b-col(lg="10" sm="12") {{ $t('englishName') }}：
          span.float-right {{ parentInfo.english_name }}
      b-row.mt-2.justify-content-md-center
        b-col(lg="10" sm="12") {{ $t('gender') }}：
          span.gender-text.float-right
            | {{ $t(parentInfo.gender) }}
            font-awesome-icon.ml-1(:icon="getGenferIcon(parentInfo.gender)" :class="[parentInfo.gender === 'male' ? 'text-primary' : 'text-danger']")
      b-row.mt-2.justify-content-md-center
        b-col(lg="10" sm="12") {{ $t('phoneNumber') }}：
          span.float-right {{ parentInfo.cell_phone_number }}
      b-row.mt-2.justify-content-md-center
        b-col(lg="10" sm="12") {{ $t('email') }}：
          span.float-right {{ parentInfo.email }}
      //- 學員後台編輯刪除按鈕先隱藏
      //- .ml-2.float-right.mt-4
      //-     b-button.edit-btn.mr-1(@click="editParentInfo" size="sm" variant="primary")
      //-       font-awesome-icon(icon='pen' size='2x')
      //-     b-button(@click="setRemoveParentInfo(parentInfo)" size="sm" variant="danger" v-b-modal.removeParentInfo)
      //-       font-awesome-icon(icon='trash' size='2x')
</template>

<script>
import iconContants from '@/constants/icon';
import commonContants from '@/constants/common';
import i18n from '@/components/parents/profileInfo/lang';

export default {
  name: 'ViewInfoCard',

  i18n,

  props: {
    parentInfo: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data(){
    return {
      editMode: 'edit',
    };
  },

  methods: {
    editParentInfo(){
      this.parentInfo.mode = this.editMode;
      this.$emit('updateCardView', true);
    },

    setRemoveParentInfo(parentInfo){
      const currentRemoveInfoData = {
        id: parentInfo.id,
        chineseName: parentInfo.chinese_name,
        englishName: parentInfo.english_name,
        relation: parentInfo.relation,
      };
      this.$store.dispatch('parents/setCurrentRemoveInfoData', currentRemoveInfoData);
    },

    getGenferIcon(gender){
      return (gender === commonContants.GENDER.MALE) ? iconContants.MALE : iconContants.FEMALE;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: 1px solid var( --text-light-color );
  box-shadow: 0px 4px 10px #00000029;
  border-radius: 15px;
  .card-title-hr {
    border-top: 1px solid var( --text-light-color );
  }
  .edit-btn {
    background-color: var( --main-dark-color );
    border-color: var( --main-dark-color );
  }
}
@media only screen and (max-width: 991px) {
  .card {
    .card-title-hr {
      margin-top: 0px;
    }
  }
}
</style>
