import commonContants from '@/constants/common';
import i18n from '@/lang/index';
/**
 * @des Option format {value: VALUE, text: TEXT}
 * @returns {Array} 回傳所有性別選項
 */
export function getGenderOptions() {
  const pleaseSelectGenderText = i18n.tc('please') + i18n.tc('select') + i18n.tc('gender');
  const genderArray = [commonContants.GENDER.MALE, commonContants.GENDER.FEMALE],
    genderOptions = [{ value: null, text: pleaseSelectGenderText }];
  genderArray.forEach((gender) => {
    const optionFormat = { value: gender, text: i18n.tc(gender) };
    genderOptions.push(optionFormat);
  });
  return genderOptions;
}

/**
 * @des Option format {value: VALUE, text: TEXT}
 * @returns {Array} 回傳目前所有學員與家人關係選項
 */
export function getRelationOptions() {
  const pleaseSelectRelationText = i18n.tc('please') + i18n.tc('select') + i18n.tc('relation');
  const relationArray = [commonContants.RELATION.FATHER, commonContants.RELATION.MOTHER, commonContants.RELATION.UNCLE, commonContants.RELATION.AUNT, commonContants.RELATION.GRANDFATHER, commonContants.RELATION.GRANDMOTHER],
    relationOptions = [{ value: null, text: pleaseSelectRelationText }];
  relationArray.forEach((relation) => {
    const optionFormat = { value: relation, text: i18n.tc(relation) };
    relationOptions.push(optionFormat);
  });
  return relationOptions;
}
