var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "h4-notification-title"
  }, [_vm._v(_vm._s(_vm.$t('parentZone')))])]), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('profile-info', {
    attrs: {
      "parentsPofileInfo": _vm.parentsPofileInfo
    },
    on: {
      "reloadParentsInfo": _vm.reloadParentsInfo
    }
  })], 1)]), _c('remove-parentInfo', {
    on: {
      "reloadParentsInfo": _vm.reloadParentsInfo
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }